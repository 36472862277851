/* eslint-disable no-unreachable */
import Reducers from '../reducers';

const initState = {};

const alianzaReducer = (state = initState,action) => {
    switch(action.type)
    {
        case Reducers.ALIANZA_GUARDAR:      
            return action.data;
        break;

        case Reducers.CUPON_ALIANZA_GUARDAR:      
            return action.data;
        break;

        case Reducers.AUTH_UNSET: 
            return initState;
        break;
        
        default: 
            return state;
        break;
    }
}

export { alianzaReducer };
