import React, { Component } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { adminLogin } from '../../store/actions';
import { Regex, Validate, RegexExtra, Config } from '../../other';
import { Grid, withStyles } from '@material-ui/core';
import { PublicLayout, InputUI, ButtonUI, AlertUI, CardModuleUI } from '../common';

class Login_ extends Component 
{
    recaptchaRef = React.createRef();  

    state={
        login: {
            as_email: {
                auth: false,
                validate: true,
                required: true,
                disabled: false,
                error: false,
                type: 'email',
                label: 'Email',
                id: 'as_email',
                name: 'as_email',
                change_param: 'login',
                regex: Regex.EMAIL,
                value: '',
                default_value: '',
                placeholder: 'algo@algo.com',
                messages: {
                    error: 'Por favor, ingrese un email válido',
                    error_extra: RegexExtra.EMAIL
                }
            },
            as_clave: {
                auth: false,
                validate: true,
                required: true,
                disabled: false,
                error: false,
                type: 'password',
                label: 'Clave',
                id: 'as_clave',
                name: 'as_clave',
                change_param: 'login',
                regex: Regex.CLAVE,
                value: '',
                default_value: '',
                placeholder: '******',
                messages: {
                    error: 'Por favor, ingrese una clave válida',
                    error_extra: RegexExtra.CLAVE
                }
            }
        },
        forms: {
            login: false
        },
        recaptcha_state: false,
        alert: {
            open: false,
            type: 'error',
            message: ''
        }
    };

    handleChange = (e,blur,aditional) => {

        let value = e.target.value;
        let name = e.target.name;

        this.setState({
            [aditional]: {
                ...this.state[aditional],
                [name]: {
                    ...this.state[aditional][name],
                    value: value
                }
            }
        },() => {
            Validate.validate_input(this.state[aditional][name],blur,(input) => {
                this.setState({
                    [aditional]: {
                        ...this.state[aditional],
                        [name]: input
                    }
                });
            });
        });
    }

    handleCloseAlert = () => {
        this.setState({
            alert: {
                ...this.state.alert,
                open: false
            }
        });
    }

    handleSubmit = (t_captcha) => {
        this.setState({
            forms: {
                ...this.state.forms,
                login: true
            },
            recaptcha_state: false
        }, () => {

            if(t_captcha)
            {
                let form = this.state.login;
                
                if(Validate.validar_formulario(form))
                {
                    this.props.adminLogin({
                        a_email: form.as_email.value,
                        a_clave: form.as_clave.value,
                        t_captcha: t_captcha
                    },(err,resp) => {
                        if(err)
                        {
                            this.setState({
                                forms: {
                                    ...this.state.forms,
                                    login: false
                                },
                                alert: {
                                    ...this.state.alert,
                                    open: true,
                                    message: resp.message
                                }
                            }, () => this.resetRecaptcha());
                        }
                    });
                }
                else
                {
                    Validate.validar_formulario_actualizar_obj(form, (new_form) => {
                        this.setState({
                            forms: {
                                ...this.state.forms,
                                login: false
                            },
                            login: new_form
                        }, () => this.resetRecaptcha());
                    });
                }
            }
            else
            {
                this.setState({
                    forms: {
                        ...this.state.forms,
                        login: false
                    },
                    alert: {
                        open: true,
                        type: 'error',
                        message: 'Ha ocurrido un error con el captcha, intente nuevamente'
                    }
                }, () => this.resetRecaptcha());
            }
            
        });
    }

    fireCaptcha = (e) => {
        e.preventDefault(); 
        this.setState({
            recaptcha_state: true
        }, () => this.recaptchaRef.current.execute());
    }
    resetRecaptcha = () => this.recaptchaRef.current.reset();


    render() 
    {
        let { classes } = this.props;
        return (
            <PublicLayout>
                <AlertUI
                    open={this.state.alert.open}
                    message={this.state.alert.message}
                    type={this.state.alert.type}
                    handleCloseAlert={this.handleCloseAlert}
                />
                <form onSubmit={this.fireCaptcha}>
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <CardModuleUI big_shadow>
                                <div className={classes.card_padding}>
                                    <div className={classes.logo_wrapper}>
                                        <Link to="/login">
                                            <img src="https://dk0k1i3js6c49.cloudfront.net/landingpage/images/v4/empretienda-green-logo.png" alt="Empretienda logo" className={classes.logo}/>
                                        </Link>
                                    </div>
                                    <InputUI
                                        handleChange={this.handleChange}
                                        input={this.state.login.as_email}
                                    />
                                    <div className={classes.separator}></div>
                                    <InputUI
                                        handleChange={this.handleChange}
                                        input={this.state.login.as_clave}
                                    />
                                    <ReCAPTCHA
                                        ref={this.recaptchaRef}
                                        size="invisible"
                                        sitekey={Config.RECAPTCHA_PUBLIC_KEY}
                                        onChange={token => this.handleSubmit(token)}
                                        onExpired={() => this.resetRecaptcha()}
                                        onErrored={() => this.resetRecaptcha()}
                                    />
                                    <div className={classes.separator}></div>
                                    <div className={classes.separator}></div>
                                    <ButtonUI
                                        type="submit"
                                        label="Ingresar"
                                        size="large"
                                        border_radius
                                        fullWidth={false}
                                        isLoading={this.state.forms.login}
                                        disabled={this.state.recaptcha_state}
                                    />
                                </div>
                            </CardModuleUI>
                        </Grid>
                    </Grid>
                </form>
            </PublicLayout>
        );
    }
}

const styles = theme => ({
    separator: {
        width:'100%',
        height: '15px'
    },
    separator_mobile: {
        [theme.breakpoints.down('xs')]: {
            width:'100%',
            height: '50px'
        }
    },
    link_left: {
        color: '#666',
        fontFamily: 'Roboto',
        lineHeight: '1',
        textAlign: 'center',
        fontSize: '0.9rem',
        marginTop: '5px',
        marginBottom: '20px',
        padding: '0',
        [theme.breakpoints.up('sm')]: {
            marginBottom: '0px',
            textAlign: 'left'
        }
    },
    link_below_left: {
        color: '#666',
        fontFamily: 'Roboto',
        lineHeight: '1',
        textAlign: 'center',
        fontSize: '0.9rem',
        marginTop: '10px',
        marginBottom: '20px',
        padding: '0',
        [theme.breakpoints.up('sm')]: {
            marginBottom: '0px',
            textAlign: 'left'
        }
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline'
        }
    },
    card_padding: {
        padding: '10px',
        [theme.breakpoints.up('sm')]: {
            padding: '10px 30px'
        }
    },
    logo_wrapper: {
        marginBottom: '30px'
    },
    logo: {
        width: '250px',
        maxWidth: '100%'
    },
});

const mapStateToProps = null;
const mapDispatchToProps = (dispatch) => {
    return {
        adminLogin: (usuario,callback) => dispatch(adminLogin(usuario,callback))
    }
}

export const Login = withStyles(styles)(connect(mapStateToProps,mapDispatchToProps)(Login_));
