import axios from 'axios';
import Reducers from '../reducers';
import { Config, Validate } from '../../other';
import { adminLogout } from '../actions';


const cuponObtenerCuponesAlianza = (callback) => {
    return (dispatch, getState) => {
        axios.get(Config.BACKEND_ENDPOINT+'/cupon-alianza').then(resp => {
            
            //guardo en redux
            const cupones = resp.data.data;
            dispatch({type: Reducers.CUPON_ALIANZA_GUARDAR, data: cupones });
            return callback(false, resp.data);

        }).catch(err => {
            if(err.response && err.response.status===401)
            {
                dispatch(adminLogout());
            }
            return callback(true, Validate.procesar_error_axios(err.response));
        });
    }
}


export { 
    cuponObtenerCuponesAlianza
};