/*
    Author: Luciano Ghione  
    Scope: Web
    Version: 1.0.0
    ModalUI is a react function component based on Material UI 
    which is used to handle modals with ease.
*/

import React from 'react';
import PropTypes from 'prop-types';
import { 
    Modal, 
    Card, 
    CardHeader, 
    CardContent,
    CardActions, 
    Divider, 
    IconButton,
    Grow,
    Grid,
    withStyles 
} from '@material-ui/core';
import {ButtonUI} from './';
import ClearIcon from '@material-ui/icons/Clear';

const ModalUI_ = (props) => {

    let {
        classes,
        open,
        title,
        subtitle="",
        modalHandler,
        id,
        aditional_param = null,
        handleSubmit,
        button_label,
        button_disabled = false,
        button_loading,
        children,
        allow_overflow = false
    } = props;

    return (
        <Modal 
            open={open} 
            disableRestoreFocus 
            onBackdropClick={() => modalHandler(false,id,aditional_param)}
            onEscapeKeyDown={() => modalHandler(false,id,aditional_param)}
        >
            <Grow in={open} timeout={300}>
                <form onSubmit={(e) => handleSubmit(e,id)} className={(allow_overflow ? classes.form_overflow : classes.form)}>
                    <Card className={(allow_overflow ? classes.card_overflow : classes.card)}>
                        <CardHeader
                            title={title}
                            subheader={subtitle}
                            classes={{root: classes.card_header,action: classes.card_action_button, title: classes.card_header_title}}
                            action={
                                <IconButton onClick={() => modalHandler(false,id,aditional_param)}>
                                    <ClearIcon />
                                </IconButton>
                            }
                        />
                        <Divider/>
                        <CardContent className={classes.card_content}>
                           {children}
                        </CardContent>
                        <Divider/>
                        <CardActions className={classes.card_actions}>
                            <Grid container spacing={0} justify="flex-end">
                                <Grid item lg={4} md={5} sm={6} xs={12}>
                                    <ButtonUI
                                        type="submit"
                                        label={button_label}
                                        disabled={button_disabled}
                                        isLoading={button_loading}
                                    />
                                </Grid>
                            </Grid>
                        </CardActions>
                    </Card>
                </form>
            </Grow>
        </Modal>
    );
}

const styles = theme => ({
    form: {
        outline: 'none !important',
        overflow: 'auto',
        maxHeight:'95%',
        width: '95%',
        [theme.breakpoints.up('sm')]: {
            width: '600px',
            marginTop: '1%',
        },
        marginTop: '3%',
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
    }, 
    form_overflow: {
        outline: 'none !important',
        overflow: 'visible',
        maxHeight:'95%',
        width: '95%',
        [theme.breakpoints.up('sm')]: {
            width: '600px',
            marginTop: '1%',
        },
        marginTop: '3%',
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
    }, 
    card: {
        borderRadius: '4px',
        outline: 'none !important',
    },
    card_overflow: {
        borderRadius: '4px',
        outline: 'none !important',
        overflow: 'visible'
    },
    card_content: theme.card.card_content,
    card_action_button: theme.card.card_action_button,
    card_actions: {
        padding: '10px 5px',
        boxSizing: 'border-box'
    },
    card_header: theme.card.card_header,
    card_header_title: theme.card.card_header_title
});

ModalUI_.propTypes = {
    
    //Object used to add some styling with withStyles
    classes: PropTypes.object,

    //true to open the modal
    open: PropTypes.bool.isRequired,

    //The title of the modal
    title: PropTypes.string.isRequired,

    //The subtitle of the modal
    //Default: ""
    subtitle: PropTypes.node,
    
    //The function which opens and closes the modal
    //Called: modalHandler(open,id,aditional_param)
    modalHandler: PropTypes.func.isRequired,

    //The id of the modal
    //It's passed as argument in modalHandler and handleSubmit
    //To identify the modal 
    id: PropTypes.string.isRequired,

    //The aditional param which 
    //is passed in modalHandler
    //Default: null
    aditional_param: PropTypes.any,

    //The form submit function
    //Called: handleSubmit(event,id)
    handleSubmit: PropTypes.func.isRequired,

    //Label of the button at the bottom
    button_label: PropTypes.string.isRequired,

    //Indicates whether the submit button disabled or not
    //Default: false
    button_disabled: PropTypes.bool,

    //Indicates  whether the submit button is loading or not
    button_loading: PropTypes.bool.isRequired,

    //The content of the modal
    children: PropTypes.node.isRequired

};

export const ModalUI = withStyles(styles)(ModalUI_);
