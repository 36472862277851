import axios from 'axios';
import Reducers from '../reducers';
import { Config, Validate } from '../../other';
import { adminLogout } from '../actions';


const alianzaActualizarClave = (data,callback) => {

    return (dispatch, getState) => {
        axios.post(Config.BACKEND_ENDPOINT+'/alianza/cambiar-clave', data).then(resp => {
            
            //guardo en redux
            const alianza = resp.data.data;
            dispatch({type: Reducers.ALIANZA_GUARDAR, data: alianza });
            return callback(false, resp.data);
            
        }).catch(err => {
            if(err.response && err.response.status===401)
            {
                dispatch(adminLogout());
            }
            return callback(true, Validate.procesar_error_axios(err.response));
        });
    }
    
}

export { 
    alianzaActualizarClave
};