import React from 'react';
import {
    Chip,
    Checkbox,
    ListItemText,
    withStyles,
    MenuItem,
    TextField
} from '@material-ui/core';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 'auto',
    },
  },
};

const SelectMultipleUI_ = (props) => {

    let {
        classes,
        data,
        options,
        value_atribute = 'value',
        label_atribute = 'label',
        label_sub_atribute = '',
        handleChangeMultipleSelect,
    } = props;

    let { 
        id,
        name, 
        label, 
        values, 
        change_param = null,
        help = ''
    } = data;

    const select_props = {
        renderValue: selected => (
            <div className={classes.chips}>
                {
                    selected.map(value => <Chip key={value} label={options.filter(option => option[value_atribute]===value)[0][label_atribute]} className={classes.chip} />)
                }
            </div>
        ),
        MenuProps: MenuProps,
        onChange: (e) => handleChangeMultipleSelect(e,change_param),
        multiple: true,
        classes: { select: classes.selected }
    }

    return (
        <TextField
            select
            id={id}
            fullWidth
            name={name}
            label={label}
            value={values}
            margin="dense"
            variant="outlined"
            SelectProps={select_props}
            InputLabelProps={{
                shrink: true
            }}
            FormHelperTextProps={{
                className: classes.helper_text
            }}
            helperText={help}
        >
            {
                options.map(option => (
                    <MenuItem key={option[value_atribute]} value={option[value_atribute]}>
                        <Checkbox checked={values.indexOf(option[value_atribute]) > -1} color="primary"/>
                        <ListItemText primary={option[label_atribute]} secondary={option[label_sub_atribute]}/>
                    </MenuItem>
                ))
            }
        </TextField>
    );
}

const styles = theme => ({
    helper_text:{
        marginLeft: 0
    },
    selected: {
        backgroundColor: 'rgba(0, 0, 0, 0)',
        '&:hover' : {
            backgroundColor: 'rgba(0, 0, 0, 0)'
        },
        '&:focus' : {
            backgroundColor: 'rgba(0, 0, 0, 0)'
        },
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: theme.spacing.unit / 4,
    },
});

export const SelectMultipleUI = withStyles(styles, { withTheme: true })(SelectMultipleUI_);

