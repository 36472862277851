/* eslint-disable no-unreachable */
import Reducers from '../reducers';

const initState = [];

const cuponAlianzaReducer = (state = initState,action) => {
    let cupones;
    switch(action.type)
    {
        case Reducers.CUPON_ALIANZA_GUARDAR:

            cupones = action.data;     
            return cupones;

        break;

        case Reducers.AUTH_UNSET:

            return initState;

        break;

        default: 

            return state;

        break;
    }
}

export { cuponAlianzaReducer };