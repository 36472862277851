
import React from 'react';  
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';


const PrivateRoute_ = ({ component: Component, ...rest }) =>  {

    let auth = rest.autenticado;
    
    if(auth)
    {
        return (
            <Route 
                {...rest} 
                render={props => <Component {...props}/> } 
            />
        );
    }
    else
    {
        return (
            <Route 
                {...rest} 
                render={props => <Redirect to={{ pathname: '/login', state: { from: props.location }}}/> }
            />
        );
    }    
}

const mapStateToProps = (state) => {
    return {
        autenticado: state.auth.autenticado
    }
}
const mapDispatchToProps = null;

export const PrivateRoute = connect(mapStateToProps,mapDispatchToProps)(PrivateRoute_);