import React from 'react';
import { Grid, Hidden, withStyles } from '@material-ui/core';

const PublicLayout_ = (props) => {

    let { classes, children } = props;

    return (
        <div className={classes.root}>
            <Grid 
                container
                direction="row"
                justify="center"
                alignItems="center"
            >
                <Grid item lg={5} md={6} xs={12}>
                    <div className={classes.input_box}>
                        { children }
                    </div>
                </Grid>
                <Hidden smDown>
                    <Grid item lg={7} md={6} xs={12}>
                        <img src="https://dk0k1i3js6c49.cloudfront.net/panel/v4/login-refriends-bg.png" className={classes.ilustracion} alt="Ilustracion"/>
                    </Grid>
                </Hidden>
            </Grid>
        </div>
    );

}

const styles = theme => ({
    root: {
        width: '100%',
        backgroundColor: '#D5EDD8',
        margin: 'auto',
        boxSizing: 'border-box',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        [theme.breakpoints.up('sm')]: {
            width: '100%'
        }
    },
    ilustracion: {
        width: '85%',
        heigth: 'auto',
        display: 'block',
        margin: 'auto',
        [theme.breakpoints.down('sm')]: {
            width: '85%',
            marginBottom: '20px',
            marginTop: '20px'
        }
    },
    input_box: {
        width: '90%',
        heigth: 'inherit',
        boxSizing: 'border-box',
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.up('sm')]: {
            width: '80%',
        }
    }
});

export const PublicLayout = withStyles(styles,{ withTheme: true })(PublicLayout_);
