import React, { Component } from 'react';
import { connect } from 'react-redux';
import { 
    Grid,
    Icon,
    withStyles, 
    Card, 
    CardHeader, 
    CardContent, 
    Divider,
    LinearProgress
 } from '@material-ui/core';
 import { 
    cuponObtenerCuponesAlianza
 } from '../../store/actions';
import { 
    PrivateLayout, 
    AlertUI, 
    ModuleTitleUI,
    InputUI
} from '../common';
import { 
    CuponAlianzaList 
} from './subcomponents';
import { Validate, Regex, RegexExtra } from '../../other';
 
class CuponAlianza_ extends Component 
{
    state={

        filter: {
            search: {
                auth: false,
                validate: false,
                required: true,
                disabled: false,
                error: false,
                type: 'text',
                label: 'Buscar',
                id: 'search',
                name: 'search',
                change_param: 'filter',
                regex: Regex.ANYTHING,
                value: '',
                default_value: '',
                placeholder: 'Código de cupón...',
                messages: {
                    error: '',
                    error_extra: RegexExtra.ANYTHING
                }
            },
        },
        selects : {
            ca_tipo: [
                {
                    value: 1,
                    label: "Cupón de días extra"
                },
                {
                    value: 2,
                    label: "Cupón de monto fijo"
                },
            ],
        },
        loading: {
            cupones: true,
            error: false
        },
        alert: {
            open: false,
            type: 'error',
            message: ''
        },
    };

    componentDidMount()
    {
        this.props.cuponObtenerCuponesAlianza((err,resp) => {
            this.setState({
                loading: {
                    ...this.state.loading,
                    cupones: false,
                    error: (this.state.loading.error || err) ? true : false
                }
            });
        });
    }

    handleChange = (e,blur,aditional) => {

        let value = e.target.value;
        let name = e.target.name;

        this.setState({
            [aditional]: {
                ...this.state[aditional],
                [name]: {
                    ...this.state[aditional][name],
                    value: value
                }
            }
        },() => {
            Validate.validate_input(this.state[aditional][name],blur,(input) => {
                this.setState({
                    [aditional]: {
                        ...this.state[aditional],
                        [name]: input
                    }
                });
            });
        });
    }

    handleChangeFilter = (e,blur,aditional) => {

        let value = e.target.value;
        let name = e.target.name;
    
        this.setState({
            [aditional]: {
                ...this.state[aditional],
                [name]: {
                    ...this.state[aditional][name],
                    value: value
                },
            }
        });
    }

    handleChangeSelect = (e,aditional) => {

        let value = Validate.trim_int(e.target.value);
        let name = e.target.name;
        let enable_arr = value===1 ? ["ca_dias"] : ["ca_monto"];
        let disable_arr = value===1 ? ["ca_monto"] : ["ca_dias"];
        let form_state = Validate.bulk_enable_disable_input(this.state[aditional], enable_arr, disable_arr, [], true);
        
        this.setState({
            [aditional]: {
                ...form_state,
                [name]: {
                    ...this.state[aditional][name],
                    value: value
                },
            }
        });

    }

    //cierra las alertas
    handleCloseAlert = () => {
        this.setState({
            alert: {
                ...this.state.alert,
                open: false
            }
        });
    }

    render() 
    {
        const { classes } = this.props;
        return (
            <PrivateLayout 
                error={this.state.loading.error} 
                url={this.props.match.url}
            >
                <AlertUI
                    open={this.state.alert.open}
                    message={this.state.alert.message}
                    type={this.state.alert.type}
                    handleCloseAlert={this.handleCloseAlert}
                />
                <ModuleTitleUI 
                    title="Cupones"
                    subtitles={[
                        'Aquí podrás ver todos los cupones'
                    ]}
                />
                <div style={{ paddingBottom: 12 }}>
                    <Grid container spacing={24}>
                        <Grid item md={4} sm={6} xs={12}>
                            <InputUI
                                handleChange={this.handleChangeFilter}
                                input={this.state.filter.search}
                                end_adornment={<Icon>search</Icon>}
                            />
                        </Grid>
                    </Grid>
                </div> 
                <Card className={classes.card_below}>
                    <CardHeader
                        title="Cupones"
                        className={classes.card_header}
                        classes={{action: classes.card_action_button}}
                    />
                    <Divider/>
                    <CardContent className={classes.card_content}>
                        {(this.state.loading.cupones) ? <LinearProgress/> : <CuponAlianzaList filtro={this.state.filter.search.value} />} 
                    </CardContent>
                </Card>
            </PrivateLayout>
        );
    }
}

const styles = theme => ({
    card: theme.card.card,
    card_header: theme.card.card_header,
    card_header_title: theme.card.card_header_title,
    card_actions: theme.card.card_actions_public,
    card_content: {
        padding: 0,
        '&:last-child': {
            padding: 0
        }
    },
});

const mapStateToProps = (state) => {
    return {
        cupones: state.cupones,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        cuponObtenerCuponesAlianza: (callback) => dispatch(cuponObtenerCuponesAlianza(callback)),
    }
}

// CuponAlianza_.propTypes = {

// };

export const CuponAlianza = withStyles(styles)(connect(mapStateToProps,mapDispatchToProps)(CuponAlianza_));
