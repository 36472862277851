import React, { Component } from 'react';
import { connect } from 'react-redux';
import { alianzaActualizarClave } from '../../store/actions';
import { Regex, RegexExtra, Validate } from '../../other';
import { Card, CardContent, Grid, withStyles } from '@material-ui/core';
import { 
    PrivateLayout, 
    InputUI, 
    ButtonUI, 
    AlertUI,
    ModuleTitleUI,
    ModuleButtonBoxUI
} from '../common';

class CambiarClave_ extends Component 
{
    state={
        cambiar_clave: {
            a_clave_actual: {
                auth: false,
                validate: true,
                required: true,
                disabled: false,
                error: false,
                type: 'password',
                label: 'Clave actual',
                id: 'a_clave_actual',
                name: 'a_clave_actual',
                change_param: 'cambiar_clave',
                regex: Regex.CLAVE,
                value: '',
                default_value: '',
                placeholder: '******',
                messages: {
                    error: 'Por favor, ingrese una clave válida',
                    error_extra: RegexExtra.CLAVE
                }
            },
            a_clave: {
                auth: false,
                validate: true,
                required: true,
                disabled: false,
                error: false,
                type: 'password',
                label: 'Nueva clave',
                id: 'a_clave',
                name: 'a_clave',
                change_param: 'cambiar_clave',
                regex: Regex.CLAVE,
                value: '',
                default_value: '',
                placeholder: '******',
                messages: {
                    error: 'Por favor, ingrese una clave válida',
                    error_extra: RegexExtra.CLAVE
                }
            },
            a_clave_repetir: {
                auth: false,
                validate: true,
                required: true,
                disabled: false,
                error: false,
                type: 'password',
                label: 'Repetir clave',
                id: 'a_clave_repetir',
                name: 'a_clave_repetir',
                change_param: 'cambiar_clave',
                regex: Regex.CLAVE,
                value: '',
                default_value: '',
                placeholder: '******',
                messages: {
                    error: 'Por favor, ingrese una clave válida',
                    error_extra: RegexExtra.CLAVE
                }
            }
        },
        loading: {
            cambiar_clave: true,
            error: false
        },
        forms: {
            cambiar_clave: false
        },
        alert: {
            open: false,
            type: 'error',
            message: ''
        }
    };

    componentDidMount()
    {
        // this.props.tiendaObtener((err,resp) => {

            this.setState({
                loading: {
                    ...this.state.loading,
                    cambiar_clave: false,
                    error: false
                }
            });

        // });
    }

    handleChange = (e,blur,aditional) => {

        let value = e.target.value;
        let name = e.target.name;

        this.setState({
            [aditional]: {
                ...this.state[aditional],
                [name]: {
                    ...this.state[aditional][name],
                    value: value
                }
            }
        },() => {
            Validate.validate_input(this.state[aditional][name],blur,(input) => {
                this.setState({
                    [aditional]: {
                        ...this.state[aditional],
                        [name]: input
                    }
                });
            });
        });
    }

    handleCloseAlert = () => {
        this.setState({
            alert: {
                ...this.state.alert,
                open: false
            }
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();

        this.setState({
            forms: {
                ...this.state.forms,
                cambiar_clave: true
            }
        });


        let form = this.state.cambiar_clave;
        
        if(Validate.validar_formulario(form))
        {
            let a_clave_actual = form.a_clave_actual.value;
            let a_clave = form.a_clave.value;
            let a_clave_repetir = form.a_clave_repetir.value;

            if(a_clave === a_clave_repetir)
            {
                this.props.alianzaActualizarClave({
                    a_clave_actual: a_clave_actual,
                    a_clave: a_clave
                },(err,resp) => {
    
                    this.setState({
                        forms: {
                            ...this.state.forms,
                            cambiar_clave: false
                        },
                        alert: {
                            ...this.state.alert,
                            open: true,
                            message: resp.message,
                            type: err ? 'error' : 'success'
                        }
                    });
                    
                });
            }
            else
            {
                this.setState({
                    forms: {
                        ...this.state.forms,
                        cambiar_clave: false
                    },
                    alert: {
                        ...this.state.alert,
                        open: true,
                        message: 'Las claves no coinciden',
                        type: 'error'
                    }
                });
            }
        }
        else
        {
            Validate.validar_formulario_actualizar_obj(form, (new_form) => {
                this.setState({
                    forms: {
                        ...this.state.forms,
                        cambiar_clave: false
                    },
                    cambiar_clave: new_form
                });
            });
        }
    }

    render() 
    {
        let classes = this.props.classes;
        return (
            <PrivateLayout 
                loading={this.state.loading.cambiar_clave} 
                error={this.state.loading.error} 
                url={this.props.match.url}
            >
                <AlertUI
                    open={this.state.alert.open}
                    message={this.state.alert.message}
                    type={this.state.alert.type}
                    handleCloseAlert={this.handleCloseAlert}
                />
                <Grid container spacing={24}>
                    <Grid item xs={12}>   
                        <form onSubmit={this.handleSubmit}>
                            <ModuleTitleUI 
                                title="Cambiar clave"
                            />
                            <Card className={classes.card}>
                                <CardContent className={classes.card_content}>
                                    <Grid container spacing={24}>
                                        <Grid item xs={12}>
                                            <InputUI
                                                handleChange={this.handleChange}
                                                input={this.state.cambiar_clave.a_clave_actual}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InputUI
                                                handleChange={this.handleChange}
                                                input={this.state.cambiar_clave.a_clave}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InputUI
                                                handleChange={this.handleChange}
                                                input={this.state.cambiar_clave.a_clave_repetir}
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                            <ModuleButtonBoxUI lgSize={4} mdSize={6} smSize={7}>
                                <ButtonUI
                                    type="submit"
                                    label="CAMBIAR CONTRASEÑA"
                                    isLoading={this.state.forms.cambiar_clave}
                                />
                            </ModuleButtonBoxUI>
                        </form>
                    </Grid>
                </Grid>
            </PrivateLayout>
        );
    }
}

const styles = theme => ({
    card: theme.card.card,
    card_header: theme.card.card_header,
    card_header_title: theme.card.card_header_title,
    card_content: theme.card.card_content,
    card_actions: theme.card.card_actions_public
});

const mapStateToProps = (state) => {
    return {
        tienda: state.tienda
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        // tiendaObtener: (callback) => dispatch(tiendaObtener(callback)),
        alianzaActualizarClave: (data,callback) => dispatch(alianzaActualizarClave(data,callback)),
    }
}

export const CambiarClave = withStyles(styles)(connect(mapStateToProps,mapDispatchToProps)(CambiarClave_));
