/* eslint-disable */
import _ from 'underscore';
import lodash from 'lodash';
import async from 'async';
import moment from 'moment';
import 'moment/locale/es';
import { Config } from '.';

class Validate
{
    /**
     * Determina si el valor ingresado es:
     * null | undefined | NaN | Infinity | Array
     * Object | RegExp | boolean.
     * @param val Valor a chequear.
     * @returns true si el valor es inseguro o falso en caso contrario.
    **/
    static is_unsafe_value(val)
    {
        if(
            val===null 
            || val===undefined 
            || val===NaN 
            || val===Infinity
            || _.isArray(val)
            || _.isObject(val)
            || _.isRegExp(val)
            || val===false
            || val===true
            || _.isFunction(val)
        )
        {
            return true;
        }
        else
        {
            return false;
        }
    }

    /**
     * Borra los espacios vacios de un string.
     * @param val: Valor para borrar espacios.
     * @returns El string sin espacios al inicio/final o '' si el valor no es string.
    **/
    static trim(val)
    {  
        return Validate.is_unsafe_value(val) ? '' : String(val).trim();
    }
    
    /**
     * Borra los espacios vacios de un string.
     * @param val: valor para borrar espacios.
     * @returns El string sin espacios al inicio/final, null si el valor es null o '' si el valor no es string.
    **/
    static trim_allow_null(val)
    {  
        if(val === null)
        {
            return null;
        }
        return Validate.trim(val);
    }

    /**
     * Borra los espacios vacios de un string y convierte el resultado a lowercase.
     * @param val: valor para borrar espacios.
     * @returns El string en lowercase sin espacios al inicio/final o '' si el valor no es string.
    **/
    static trim_lowercase(val)
    {
        return Validate.trim(val).toLowerCase();
    }

    /**
     * Transformo el string para que sea formato clave
     * Ej: hola Que tal -> holaquetal
     * @param {*} val 
     */
    static trim_keyfy(val)
    {
        return Validate.trim_lowercase(val).replace(/\s/g,'');
    }

    /**
     * Borra los espacios vacios de un string y convierte el resultado a lowercase.
     * @param val: valor para borrar espacios.
     * @returns El string en lowercase sin espacios al inicio/final, null si el valor es null o '' si el valor no es string.
    **/
    static trim_lowercase_allow_null(val)
    {
        if(val === null)
        {
            return null;
        }
        return Validate.trim_lowercase(val);
    }

    /**
     * Borra los espacios vacios de un string y convierte el resultado a uppercase.
     * @param val: valor para borrar espacios.
     * @returns El string en uppercase sin espacios al inicio/final o '' si el valor no es string.
    **/
    static trim_uppercase(val)
    {
        return Validate.trim(val).toUpperCase();
    }
    
    /**
     * Borra los espacios vacios de un string y convierte el resultado a uppercase.
     * @param val: valor para borrar espacios.
     * @returns El string en uppercase sin espacios al inicio/final, null si el valor es null o '' si el valor no es string.
    **/
    static trim_uppercase_allow_null(val)
    {
        if(val === null)
        {
            return null;
        }
        return Validate.trim_uppercase(val);
    }

    /**
     * Borra los espacios vacios de un string, convierte las primeras letras de las palabras 
     * en uppercase y el resto en lowercase
     * @param val: valor para borrar espacios.
     * @returns El string con mayusculas al inicio de cada palabra y minuscula en el resto
     * sin espacios al inicio/final o '' si el val no es string.
    **/
    static trim_ucwords(val)
    {
        return Validate.trim_lowercase(val).replace(/^.|\s\S/g, a => a.toUpperCase());
    }

    /**
     * Borra los espacios vacios de un string, convierte las primeras letras de las palabras 
     * en uppercase y el resto en lowercase.
     * @param val: valor para borrar espacios.
     * @returns El string con mayusculas al inicio de cada palabra y minuscula en el resto
     * sin espacios al inicio/final, null si val es nulo o '' si el valor no es string.
    **/
    static trim_ucwords_allow_null(val)
    {
        if(val===null)
        {
            return null;
        }
        return Validate.trim_ucwords(val);
    }

    /**
     * Borra los espacios vacios de un string y lo convierte en int.
     * @param val: Valor para borrar espacios.
     * @returns val convertido a int o 0 si no es numero.
    **/
    static trim_int(val)
    {
        let number = parseInt(Validate.trim(val));
        return _.isNaN(number) ? 0 : number;
    }

    /**
     * Borra los espacios vacios de un string y lo convierte en int
     * @param val: Valor para borrar espacios.
     * @returns val convertido a int, 0 si val no es un numero o null si val es null.
    **/
    static trim_int_allow_null(val)
    {
        if(val === null)
        {
            return null;
        }
        return Validate.trim_int(val);
    }
    
    /**
     * Borra los espacios vacios de un string y lo convierte en float.
     * @param val: Valor para borrar espacios.
     * @returns val convertido a float o 0 si no es numero.
    **/
    static trim_float(val)
    {
        let number = parseFloat(Validate.trim(val));
        return _.isNaN(number) ? 0 : number;
    }

    /**
     * Borra los espacios vacios de un string y lo convierte en float.
     * @param val: Valor para borrar espacios.
     * @returns val convertido a float, 0 si val no es numero o null si val es null.
    **/
    static trim_float_allow_null(val)
    {
        if(val===null)
        {
            return null;
        }
        return Validate.trim_float(val);
    }

    /**
     * Revisa si un valor es null,'',' ' o undefined
     * @param {*} str
     * @returns true si es falsy false en oc 
     */
    static is_falsy(str)
    {
        if(str===null || str ==='' || str===' ' || str===undefined)
        {
            return true;
        }
        else
        {
            return false;
        }
    }

    /**
     * Revisa si un objeto de javascript posee una key especifica.
     * @param obj: objeto javascript.
     * @param key: clave que se desea buscar.
     * @returns true si la clave existe y false si no.
    **/
    static key_exists(obj, key)
    {
        return _.has(obj,key);
    }

    /**
     * Revisa si un objeto de javascript posee un todo un conjunto de keys.
     * @param obj: objeto javascript.
     * @param keys: claves que debe tener el objeto.
     * @returns true si las claves existen y false si no.
    **/
    static all_keys_exists(obj, keys)
    {
        let i;
        for(i=0;i<keys.length;i++)
        {
            if(!Validate.key_exists(obj,keys[i]))
            {
                return false;
            }
        }
        return true;
    }
  
    /**
     * Revisa si un elemento es un array.
     * @param val: cualquier valor.
     * @returns true si es array o false en caso contrario.
    **/
    static is_array(val)
    {
        return _.isArray(val);
    }

    /**
     * Revisa si un elemento es un string.
     * @param val: objeto javascript.
     * @returns true si es string o false en caso contrario.
    **/
    static is_string(val) 
    {
        return _.isString(val);
    }

    /**
     * Revisa si un elemento es un numero (positivo opcionalmente).
     * @param val: el numero a probar.
     * @param positive: true si se requiere que sea estrictamente positivo o false en caso contrario.
     * @returns true si es numero (positivo opcionalmente) y false en caso contrario o si es NaN | Infinity.
    **/
    static is_number(val, positive)
    {
        if(_.isNumber(val) && !_.isNaN(val) && val!==Infinity)
        {
            return positive ? val>0 : true;
        }
        else
        {
            return false;
        }
    }

    /**
     * Revisa si un valor se encuentra en un array.
     * @param val: valor a buscar.
     * @param arr: array de valores.
     * @returns true fue encontrado o false en caso contrario.
    **/
    static in_array(str, arr)
    {
        for(let i=0;i<arr.length;i++)
        {
            if(str === arr[i])
            {
                return true;
            }
        }
        return false;
    }

    /**
     * Revisa si un valor se encuentra en un array de objetos.
     * @param val: valor a buscar.
     * @param arr: array de valores.
     * @param key: key en el objeto
     * @returns true fue encontrado o false en caso contrario.
    **/
    static in_array_obj(str, arr, key)
    {
        for(let i=0;i<arr.length;i++)
        {
            if(str === arr[i][key])
            {
                return true;
            }
        }
        return false;
    }
  
    /**
     * Procesa los catch de axios
     */
    static procesar_error_axios(err_response)
    {
        if(err_response && err_response.data && err_response.data.message)
        {
            return err_response.data;
        }
        else
        {
            return {
                status: 500,
                message: 'Ha ocurrido un error inesperado',
                data: []
            }
        }
    }

    /**
     * Formatea un numero 1000.99 de forma 1.000,99
     * @param number: valor a convertir
     * @returns string formateado de number
    **/
    static number_format(number)
    {
        return Validate.trim_float(number).toLocaleString("de-DE", {minimumFractionDigits: 2, maximumFractionDigits: 2});
    }

    /**
     * Calcula el porcentaje de un numero y lo redondea a dos decimales.
     * @param val: el valor del cual se calculara el porcentaje.
     * @param percent: porcentaje deseado.
     * @returns el valor en flotante del porcentaje del numero redondeado a dos valores decimales.
    **/
    static get_fixed_percent(val, percent)
    {
        return Validate.trim_float((val * (percent/100))).toFixed(2);
    } 

    /**
     * Valido la longitud de un string.
     * @param str: el string.
     * @param min: minimo de caracteres permitidos.
     * @param max: maximo de caracteres permitidos.
     * @returns true si cumple el requerimiento o false en caso contrario.
    **/
    static validate_length_string(str, min, max)
    {
        let sz = str.length;   
        return sz>=min && sz<=max;
    }

    /**
     * Verifica si un string posee un substring o no
     * @param string str 
     * @param string substr
     * @return true si lo contiene o false si no
     */
    static contiene_substring(str,substr)
    {
        return str.includes(substr);
    }

    /**
     * Dado un objeto de javascript, le retiro las claves que deseo/
     * @param obj: el objeto javascript.
     * @param blacklist: array de claves que quiero quitar.
     * @returns el objeto javascript sin las claves.
    **/
    static omit_keys(obj, blacklist)
    {
        return _.omit(obj,blacklist);
    }

    /**
     * Revisa si un numero se encuentra en un rango.
     * @param val: valor a chequear.
     * @param min: minimo valor aceptado.
     * @param max: maximo valor aceptado.
     * @returns true si pertenece o false en caso contrario.
    **/
    static validate_range(val, min, max)
    {
        return (val>=min && val<=max);
    }

    /**
     * Reemplaza los \n por <br/> en el string
     * @param {*} val 
     */
    static nl2br(val)
    {
        if(val===null)
        {
            return '';
        }
        else
        {
            return val.replace(/\r?\n/g, '<br/>');
        }
    }

    /**
     * Chequeo si el dispositivo conectado es mobile
     * @returns true si es mobile, falso en caso contrario
    */
    static is_mobile()
    {
        return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)); 
    }

    /**
     * Chequeo si el dispositivo conectado es iphone
     * @returns true si es un iphone, falso en caso contrario
    */
    static is_iphone()
    {
        return (/iPhone/i.test(navigator.userAgent)); 
    }

    /**
     * Duermo la ejecucion por 'milisegundos' milisegundos
     * @param {*} milisegundos 
     * @returns promise
     */
    static sleep(milisegundos) 
    {
        return new Promise(resolve => setTimeout(resolve, milisegundos));
    }

    /**
     * Dado un input del estilo de InputUI, lo valido y devuelvo el objeto actualizado
     * @param {*} input 
     * @param {*} fire_error 
     * @param {*} callback 
     * @returns el objeto actualizado
     */
    static validate_input(input,fire_error,callback)
    {
        if(!input.required && (input.value==='' || input.value===null))
        {
            //no hay que validar
            input.auth = true;
            input.error = false;
            return callback(input);
        }
        else
        {
            //debo validar la regex
            let input_aux = input.value;
            input.value = Validate.trim(input.value);
            let regex = new RegExp(input.regex); 
            if(regex.test(input.value))
            {
                //es valido
                input.auth = true;
                input.error = false;
                input.value = input_aux;
                return callback(input);
            }
            else
            {
                //es invalido
                input.auth = false;
                input.error = (fire_error) ? true : false;
                input.value = input_aux;
                return callback(input);
            }
        }
    }

    /**
     * Dado un input del estilo de InputUI, lo valido y devuelvo el objeto actualizado sincronicamente
     * @param {*} input 
     * @param {*} fire_error 
     * @returns el objeto actualizado
     */
    static validate_input_sync(input,fire_error)
    {
        if(!input.required && (input.value==='' || input.value===null))
        {
            //no hay que validar
            input.auth = true;
            input.error = false;
            return input;
        }
        else
        {
            //debo validar la regex
            let input_aux = input.value;
            input.value = Validate.trim(input.value);
            let regex = new RegExp(input.regex); 
            if(regex.test(input.value))
            {
                //es valido
                input.auth = true;
                input.error = false;
                input.value = input_aux;
                return input;
            }
            else
            {
                //es invalido
                input.auth = false;
                input.error = (fire_error) ? true : false;
                input.value = input_aux;
                return input;
            }
        }
    }

    /**
     * dado un objeto formulario de objetos de
     * InputUI y SelectUI, determino si son validos
     * dichos datos
     * Obs: no modifica form_obj
     * @param form_obj (el objeto formulario estandarizado)
     * @returns true si los datos del formulario son validos, false en oc
    */
    static validar_formulario(form_obj)
    {
        let flag = true;
        Object.keys(form_obj).forEach((key) => {
            if(form_obj[key].validate)
            {
                if(!form_obj[key].auth)
                {
                    flag = false;
                }
            }
        });
        return flag;
    }
    
    /**
     * dado un objeto formulario de objetos de
     * InputUI y SelectUI, muestro los errores
     * que causaron que validar_formulario de false
     * Obs: modifica form_obj 
     * @param form_obj (el objeto formulario estandarizado)
     * @param callback la funcion
     * @param avoid_empties (si el input posee valor nulo o vacio, no lo valida)
     * @returns el form_obj validado completamente
     */
    static validar_formulario_actualizar_obj(form_obj,callback,avoid_empties = false)
    {
        let keys = Object.keys(form_obj);
        async.each(keys, (key,callback) => {

            if(avoid_empties && (form_obj[key].value === '' || form_obj[key].value === null))
            {
                return callback();
            }
            else
            {
                if(form_obj[key].validate)
                {
                    Validate.validate_input(form_obj[key], true, (input) => {
        
                        form_obj[key] = input;
        
                        return callback();
        
                    });
                }
                else
                {
                    return callback();
                }
            }

        },(err) => {

            return callback(form_obj);

        });
    }

    /**
     * dado un objeto formulario de objetos de
     * InputUI y SelectUI, muestro los errores
     * que causaron que validar_formulario de false
     * Obs: modifica form_obj 
     * @param form_obj (el objeto formulario estandarizado)
     * @param avoid_empties (si el input posee valor nulo o vacio, no lo valida)
     * @returns el form_obj validado completamente
     */
    static validar_formulario_actualizar_obj_sync(form_obj,avoid_empties = false)
    {
        Object.keys(form_obj).forEach((key) => {

            if(avoid_empties && (form_obj[key].value === '' || form_obj[key].value === null))
            {}
            else
            {
                if(form_obj[key].validate)
                {
                    form_obj[key] = Validate.validate_input_sync(form_obj[key],true);
                }
            }

        });

        return form_obj;
    }

    /**
     * dado un objeto formulario de objetos de
     * InputUI y SelectUI, limpio los formularios con su campo
     * default_value
     * Obs: modifica form_obj
     * @params form_obj (el objeto formulario estandarizado)
     * @params exclude (los campos que se quiere ignorar)(opcional)
     * @returns el form_obj limpiado completamente
     */
    static limpiar_formulario(form_obj, exclude = [])
    {
        let keys = Object.keys(form_obj);

        keys.forEach((key) => {

            if(!Validate.in_array(key,exclude))
            {
                let item = form_obj[key];
    
                if(item.validate && !item.disabled)//es un input no desabilitado
                {
                    item.auth = item.required ? false : true;
                    item.error = false;
                }

                item.value = item.default_value;
    
                form_obj[key] = item; 
            }

        });

        return form_obj;
    }

    /**
     * Dado un objeto de SelectUI o InputUI, 
     * lo habilito/deshabilito para ser validado
     * Obs: modifica item
     * @params item (InputUI o SelectUI obj)
     * @params enable/disable
     * @params clean (limpia valor o no)
     * @return objeto modificado
     */
    static enable_disable_input(item,enable_disable,clean,only_read)
    {
        if(clean)
        {
            item.value = item.default_value!==undefined ? item.default_value : item.value;
            if(item.default_value!==undefined && item.default_value!=='')
            {
                item.auth = true;
            }
            else
            {
                item.auth = item.required ? false : true;
            }
            item.error = false;
        }

        item.disabled = only_read;
        
        item.validate = enable_disable;

        return item;
    }

    /**
     * Activo o desactivo los inputs de un objeto en particular
     * @param {*} obj objeto de inputs
     * @param {*} enable_arr los inputs a habilitar
     * @param {*} disable_arr los inputs a deshabilitar
     * @param {*} disable_disable_arr los inputs a deshabilitar, incluso los hace de solo lectura
     * @param {*} disable_clean true si quiero que se limpien los inputs deshabilitados, false en caso contrario 
     */
    static bulk_enable_disable_input(obj,enable_arr,disable_arr,disable_disable_arr,disable_clean = false)
    {
        Object.keys(obj).forEach(item_key => {

            if(Validate.in_array(item_key,enable_arr) && obj[item_key].validate===false)
            {
                obj[item_key] = Validate.enable_disable_input(obj[item_key],true,false,false);
            }
            else if(Validate.in_array(item_key,disable_arr) && obj[item_key].validate===true)
            {
                obj[item_key] = Validate.enable_disable_input(obj[item_key],false,disable_clean,false);
            }
            else if(Validate.in_array(item_key,disable_disable_arr))
            {
                obj[item_key] = Validate.enable_disable_input(obj[item_key],false,disable_clean,true);
            }

        });

        return obj;
    }

    /**
     * Cargo la info de data en state y valido los datos
     * Obs: modifica state
     * @params el array de valores 
     * @params state (el objeto formulario estandarizado)
     * @returns el form_obj con la info de data y validado
     */
    static cargar_formulario(data,state,callback)
    {
        let data_keys = Object.keys(data);

        data_keys.forEach((key) => {

            if(state[key]!==undefined && data[key]!==null)
            {
                state[key] = {
                    ...state[key],
                    value: data[key]
                }
            }

        });

        Validate.validar_formulario_actualizar_obj(state,(form_obj) => {
            return callback(form_obj);
        });
    }

    /**
     * Cargo la info de data en state y valido los datos de ellos excepto de los que tienen valor nulo
     * Obs: modifica state
     * @params el array de valores 
     * @params state (el objeto formulario estandarizado)
     * @returns el form_obj con la info de data y validado
     */
    static cargar_formulario_sin_nulos(data,state,callback)
    {
        let data_keys = Object.keys(data);

        data_keys.forEach((key) => {

            if(state[key]!==undefined && data[key]!==null)
            {
                state[key] = {
                    ...state[key],
                    value: data[key]
                }
            }

        });

        Validate.validar_formulario_actualizar_obj(state,(form_obj) => {
            return callback(form_obj);
        },true);
    }

    /**
     * Cargo la info de data en state y valido los datos de ellos excepto de los que tienen valor nulo
     * Obs: modifica state
     * @params el array de valores 
     * @params state (el objeto formulario estandarizado)
     * @returns el form_obj con la info de data y validado
     */
    static cargar_formulario_sin_nulos_sync(data,state)
    {
        let data_keys = Object.keys(data);

        data_keys.forEach((key) => {

            if(state[key]!==undefined && data[key]!==null)
            {
                state[key] = {
                    ...state[key],
                    value: data[key]
                }
            }

        });

        return Validate.validar_formulario_actualizar_obj_sync(state,true);
    }

    /**
     * Cargo la info de data en state y valido los datos de ellos excepto de los que tienen valor nulo
     * Obs: modifica state
     * @params el array de valores 
     * @params state (el objeto formulario estandarizado)
     * @params excludedArr (array de campos que no deben cargarse)
     * @returns el form_obj con la info de data y validado
     */
    static cargar_formulario_sin_nulos_with_excluded(data,state,excludedArr,callback)
    {
        let data_keys = Object.keys(data);

        data_keys.forEach((key) => {

            if(state[key]!==undefined && data[key]!==null && !Validate.in_array(key,excludedArr))
            {
                state[key] = {
                    ...state[key],
                    value: data[key]
                }
            }

        });

        Validate.validar_formulario_actualizar_obj(state,(form_obj) => {
            return callback(form_obj);
        },true);
    }

    /**
     * Cargo la info de data en state y valido los datos de ellos excepto de los que tienen valor nulo
     * Obs: modifica state
     * @params el array de valores 
     * @params state (el objeto formulario estandarizado)
     * @params excludedArr (array de campos que no deben cargarse)
     * @returns el form_obj con la info de data y validado
     */
    static cargar_formulario_sin_nulos_with_excluded_sync(data,state,excludedArr)
    {
        let data_keys = Object.keys(data);

        data_keys.forEach((key) => {

            if(state[key]!==undefined && data[key]!==null && !Validate.in_array(key,excludedArr))
            {
                state[key] = {
                    ...state[key],
                    value: data[key]
                }
            }

        });

        return Validate.validar_formulario_actualizar_obj_sync(state,true);
    }

    /**
     * Busco en el arreglo de objetos data, un data[key] = value 
     * y devuelvo su objeto
     * @param {*} data 
     * @param {*} key 
     * @param {*} value 
     * @returns el objeto encontrado o {} si no lo encuentra
     */
    static find_object(data, key, value)
    {
        for(let i = 0; i<data.length; i++)
        {
            if(data[i][key] === value)
            {
                return data[i];
            }
        }
        return {};
    }

    /**
     * Convierto la primer letra de un string a mayuscula
     * @param string str 
     * @return string con la primer letra en mayuscula
     */
    static capitalize(str)
    {
        return lodash.capitalize(str);
    }

    /**
     * Convierto una fecha de utc a la fecha del cliente
     * @param string datetime datetime
     * @return momentjs instance con fecha convertida a la zona horaria del cliente
     */
    static utc2local(datetime)
    {
        return moment.utc(datetime).local();
    }

    /**
     * Formateo una fecha a dd/mm/aa hh:mm 
     * @param string datetime datetime
     * @return string fecha formateada
     */
    static format_date(datetime)
    {
        return moment(datetime).format(Config.DATE_FORMAT);
    }

    /**
     * Convierto una fecha utc a la local del cliente y la formateo
     * @param string datetime datetime
     * @param string format momentjs format
     * @return string fecha formateada
     */
    static utc2local_format(datetime,format)
    {
        return Validate.utc2local(datetime).format(format);
    }

    /**
     * Convierte la fecha de utc a la zona horaria del cliente y luego calcula el tiempo que paso
     * @param datetime datetime 
     * @return string con fecha convertida a la zona horaria del cliente y en formato relativo
     */
    static utc2relative_local(datetime)
    {
        return Validate.utc2local(datetime).fromNow();
    }

    /**
     * Chequeo si la fecha 1 es anterior a la fecha 2
     * @param momentjs instance date1 
     * @param momentjs instance date2
     * @return boolean true o false
     */
    static moment_comparar(date1,date2)
    {
        return date1 < date2;
    }

    /**
     * Convierto una instancia de momentjs con horario local a utc
     * @param momentjs instance date 
     * @returns momentjs instance con fecha convertida a utc
     */
    static moment_local2utc(date)
    {
        return date.utc();
    }

    /**
     * Convierto una instancia de momentjs a utc 
     * y luego devuelvo un string datetime en utc 
     * formateado
     * @param momentjs instance date 
     * @returns string datetime datetime en utc
     */
    static moment_local2utc_format(date)
    {
        return Validate.moment_local2utc(date).format(Config.DATETIME_FORMAT);
    }

    /**
     * Devuelve el array sin los duplicados
     * @param {*} arr 
     * @param {*} fn funcion iteradora para cada elemento (opcional)
     */
    static remove_duplicates(arr,fn = null)
    {
        if(fn!==null)
        {
            return lodash.uniqBy(arr,fn);
        }
        else
        {
            return lodash.uniq(arr);
        }
    }

    /**
     * Reemplazo un string por otro 
     * @param {*} arr1 
     * @param {*} arr2 
     */
    static arr_intersection(arr1,arr2)
    {
        return _.intersection(arr1,arr2);
    }


    /**
     * Reemplazo un string por otro 
     * @param {*} arr1 
     * @param {*} arr2 
     */
    static reemplazar_string(str,old_substr,new_substr)
    {
        return str.replace(old_substr,new_substr);
    }

    /**
     * Obtengo el label de metodo de envio
     * @param int metodo_envio
     * @param json envio_seleccionado
     */
    static label_metodo_envio(metodo_envio,envio_seleccionado)
    {
        switch(metodo_envio)
        {
            case 1:
                return 'Mercado Envíos';
            break;
            case 2:
                return envio_seleccionado.nombre;
            break;
            case 3:
                return 'Acordar envío';
            break;
            case 5:
                return envio_seleccionado.nombre;
            break;
            case 6:
                return 'OCA';
            break;
            case 7:
                return 'Email';
            break;
            case 8:
                return 'Correo Argentino';
            break;
        }
    }

    /**
     * Obtengo el label de metodo de pago
     */
    static label_metodo_pago(metodo_pago)
    {
        switch(metodo_pago)
        {
            case 1:
                return 'Mercado Pago';
            break;
            case 2:
                return 'Efectivo';
            break;
            case 3:
                return 'Acordar pago';
            break;
            case 4:
                return 'Todo Pago';
            break;
            case 5:
                return 'Transferencia / Depósito';
            break;
            case 6:
                return 'Mobbex';
            break;
        }
    }

    /**
     * Obtengo el label de estado de pago
     */
    static label_estado_pago(estado_pago)
    {
        switch(estado_pago)
        {
            case -1:
                return "Esperando pago";
            break;
            case 0:
                return 'Pendiente';
            break;
            case 1:
                return 'Finalizado';
            break;
            case 2:
                return 'En proceso';
            break;
            case 3:
                return 'En mediación';
            break;
            case 4:
                return 'Rechazado';
            break;
            case 5:
                return 'Cancelado';
            break;
            case 6:
                return 'Pago devuelto';
            break;
            case 7:
                return 'En contracargo';
            break;
        }
    }

    /**
     * Obtengo el label de estado de envio
     */
    static label_estado_envio(metodo_envio,estado_envio)
    {
        switch(estado_envio)
        {
            case 0:
                if(metodo_envio===2)
                {
                    return 'Pendiente de retiro';
                }
                else
                {
                    return 'Pendiente';
                }
            break;
            case 1:
                if(metodo_envio===2)
                {
                    return 'Esperando retiro';
                }
                else
                {
                    return 'En preparación';
                }
            break;
            case 2:
                if(metodo_envio===2)
                {
                    return 'Retirado';
                }
                else
                {
                    return 'Enviado';
                }
            break;
        }
    }

    /**
     * Obtengo el label del costo del envio
     */
    static label_costo_envio(metodo_envio,costo_envio,envio_gratis,envio_seleccionado)
    {
        switch(metodo_envio)
        {
            case 2:
                return '-';
            break;
            case 3:
                return '-';
            break;
            case 5:
                if(envio_seleccionado.cobro_envio===0)
                {
                    return 'Gratis';
                }
                else if(envio_seleccionado.cobro_envio===3)
                {
                    return '-';
                }
                else
                {
                    return '$'+Validate.number_format(costo_envio);
                }
            break;
            case 7:
                return '-';
            break;
            default:
                if(envio_gratis)
                {
                    return 'Gratis';
                }
                else
                {
                    return '$'+Validate.number_format(costo_envio);
                }
            break;
        }
    }

    /**
     * Reviso si se requiere agregar dimensiones a los productos
     * @param Object metodos_envio
     * @return bool
     */
    static dimensiones_requeridas(metodos_envio)
    {
        let requeridas = (
            metodos_envio.me_oca 
            || metodos_envio.me_correo_argentino
            || metodos_envio.me_mercadoenvios 
            || metodos_envio.me_personalizados
        ) ? true : false;
        return requeridas;
    }


    /***************/
    /*  Emprepack  */
    /***************/

    static translate_message(message_code)
    {
        switch(message_code)
        {
            case -2: 
                return 'Datos actualizados correctamente';
            case -1:
                return 'Datos solicitados obtenidos correctamente';
            case 0: 
                return 'Datos creados correctamente';
            case 1: 
                return 'Error de validación de datos por expresión regular o semántica';
            case 2:
                return 'La solicitud enviada no cumple con los parámetros necesarios';
            case 3: 
                return 'ID de entidad inválido';
            case 4: 
                return 'Error al validar una estructura de datos solicitada';
            case 5: 
                return 'Error al validar un parámetro opcional de búsqueda';
            case 6: 
                return 'Error de combinación de método/submétodo de envío';
            case 7:
                return 'Error al validar métodos de pago según partner';
            case 1001: 
                return 'El Partner (proveedor logístico) solicitado no se encuentra';
            case 1002:
                return 'El Courier solicitado no se encuentra';
            case 1003:
                return 'El Customer solicitado no se encuentra';
            case 1004:
                return 'El Envío solicitado no se encuentra'
            case 1005:
                return 'Error al obtener los items del envío de la base de datos';
            case 2001:
                return 'Excepción operativa capturada durante la ejecución';
            case 2002:
                return 'Ha ocurrido un error al cargar un archivo';
            case 3001: 
                return 'Error al intentar obtener el listado de las oficinas del Courier: Andreani';
            case 3002:
                return 'Error al tarifar un envío con courier: Andreani';
            case 3003:
                return 'Error al validar oficinas de origen para envío en el Courier: Andreani';
            case 3004:
                return 'Error al validar oficinas de destino para envío en el Courier: Andreani';
            case 3005:
                return 'Error al generar link de pago para Mobbex';
            case 3006:
                return 'Error al generar link de pago para MercadoPago';
            case 3007:
                return 'Error al confirmar un envío con el courier: Andreani';
            case 4001:
                return 'El Courier solicitado no está disponible para operar en este momento';
            case 4002:
                return 'El email propuesto para crear el Cliente no se encuentra disponible';
            case 4003: 
                return 'La cuenta se encuentra bloqueada';
            case 4004: 
                return 'La cuenta se encuentra bajo proceso de validación';
            case 4005:
                return 'La cuenta presenta deudas';
            case 4006: 
                return 'La verificación de datos ha sido rechazada; debe ser enviada nuevamente';
            case 5001:
                return 'Peso excedido al tarifar un envío con courier: Andreani';
            case 5002:
                return 'El envío solicitado no está en el estado correspondiente para confirmarse';
            case 5003: 
                return 'El pago no cubre el costo del envío';
            case 5004: 
                return 'El envío ya se encuentra pago';
            case 6001:
                return 'Error de autorización o autenticación';
            case 6002:
                return 'Match de caso no contemplado en el endpoint';
        }
    }
}

export { Validate };