/*
    Author: Luciano Ghione  
    Scope: Web
    Version: 1.0.0
    DropdownUI is a react function component based on Material UI which is used to handle 
    user advanced controlled dropdowns in an easy and wide way.

    Observations:
        - All childrens must be <MenuItem> nodes (or similar)
        - It has all props that ButtonUI has.
*/

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Menu, IconButton, Icon, withStyles } from '@material-ui/core';
import { ButtonUI } from './';

const DropdownUI_ = (props) => {

    const [ menu, openMenu ] = useState(null);

    const { 
        classes, 
        children, 
        label = '', 
        size = 'medium', 
        variant = 'contained',
        fullWidth = true, 
        isLoading = false,
        type = 'button',
        icon = 'more_vert'
    } = props;

    const openMenuHandler = (e) => {
        openMenu(e ? e.currentTarget : null);
    }

    let menu_html = (
        <Menu
            open={Boolean(menu)}
            anchorEl={menu}
            onClose={(e) => openMenuHandler(null)}
            getContentAnchorEl={null}
            classes={{paper: classes.paper}}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
        >
            {
                React.Children.map(children, (child) => React.cloneElement(child, {openMenuHandler}))
            }
        </Menu>
    );

    if(type==='button')
    {
        return (
            <div>
                <ButtonUI
                    isLoading={isLoading}
                    type="callf"
                    label={label}
                    fullWidth={fullWidth}
                    size={size}
                    variant={variant}
                    onClickFunc={(e) => openMenuHandler(e)}
                />
                {menu_html}
            </div>
        );
    }
    else
    {
        return (
            <div>
                <IconButton aria-label="Icon button" onClick={(e) => openMenuHandler(e)}>
                    <Icon>{icon}</Icon>
                </IconButton>
                {menu_html}
            </div>
        );
    }
    
}

const styles = theme => ({
    paper: {
        borderRadius: '2px',
    }
});

DropdownUI_.propTypes = {
    isLoading: PropTypes.bool,
    label: PropTypes.any,
    fullWidth: PropTypes.bool,
    type: PropTypes.oneOf(['button','icon']),
    size: PropTypes.oneOf(['small', 'medium', 'large']),
}

export const DropdownUI = withStyles(styles)(DropdownUI_);
