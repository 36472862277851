/*
    Author: Luciano Ghione  
    Scope: Web
    Version: 1.0.0
    MenuItemUI is a react function component based on Material UI which is used to handle menu
    items (links) in an easy and wide way.
*/
/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { MenuItem, withStyles } from '@material-ui/core';


const MenuItemUI_ = (props) => {

    const {
        children, 
        link, 
        classes, 
        type, 
        onClickFunction,
        openMenuHandler,
        open_new_tab = false, 
        external = false
    } = props;

    if(type==='callf')
    {
        return (
            <MenuItem onClick={(e) => { openMenuHandler(null);onClickFunction(e); }}>
                {children}
            </MenuItem>
        );
    }
    else
    {
        if(external)
        {   
            if(open_new_tab)
            {
                return (
                    <a href={link} target="_blank" className={classes.link}>
                        <MenuItem>
                            {children}
                        </MenuItem>
                    </a>
                );
            }
            else
            {
                return (
                    <MenuItem>
                        <a href={link} className={classes.link}>
                            {children}
                        </a>
                    </MenuItem>
                );
            }
        }
        else
        {
            return (
                <MenuItem component={(props) => <Link to={link} {...props}/>}>
                    {children}
                </MenuItem>
            );
        }
    }
   
}

const styles = theme => ({
    link: {
        color: 'inherit',
        textDecoration: 'none'
    }
});

MenuItemUI_.propTypes = {

    //Specifies the behavior of the menu item 
    type: PropTypes.oneOf(['callf','link']).isRequired,

    //this function is only required if type is callf
    onClickFunction: PropTypes.func,

    //this link is only required if type is link
    link: PropTypes.string,

    //specifies whether the link opens a new tab or not
    open_new_tab: PropTypes.bool,

    //specifies whether the link is an internal or external link
    external: PropTypes.bool

};

export const MenuItemUI = withStyles(styles)(MenuItemUI_);
