import axios from 'axios';
import Reducers from '../reducers';
import { Config, Validate } from '../../other';
import { adminLogout } from '.';

const cuponObtenerLeads = (data, callback) => {
    return (dispatch, getState) => {
        axios.get(`${Config.BACKEND_ENDPOINT}/cupon-alianza/${data.idCuponAlianza}`).then(resp => {
            
            //guardo en redux
            const leads = [resp.data.data];
            dispatch({type: Reducers.CUPON_LEADS_GUARDAR, data: leads });
            return callback(false, resp.data);

        }).catch(err => {
            if(err.response && err.response.status===401)
            {
                dispatch(adminLogout());
            }
            return callback(true, Validate.procesar_error_axios(err.response));
        });
    }
}

export { 
    cuponObtenerLeads
};