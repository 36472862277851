import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { adminLogout } from '../../store/actions';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { 
    AppBar, 
    Button,
    IconButton,
    Icon,
    Toolbar,
    Menu,
    MenuItem,
    LinearProgress,
    withStyles
} from '@material-ui/core';
import { ErrorLayout, ToggleUI } from './';
import {Validate} from '../../other';

const is_mobile = Validate.is_mobile();

class PrivateLayout_ extends Component 
{
    state = {
        nestedMenu: {
            clientes_emprepack: false,
        },
        mobileOpen: false,
        anchorEl: null,
        profileMenuOpen: false
    };

    //abre y cierra el menu lateral
    handleDrawerToggle = () => this.setState(state => ({ mobileOpen: !state.mobileOpen }));

    //abre y cierra el menu del icono de admin
    handleProfileMenu = (e,action) => {
        if(action==='open')
        {
            this.setState({ anchorEl: e.currentTarget, profileMenuOpen: true });
        }
        else
        {
            this.setState({ anchorEl: null, profileMenuOpen: false });
        }
    }

    //cierra la sesion
    logout = (e) => {
        e.preventDefault();
        this.props.adminLogout();
    }

    render() 
    {
        const { classes, loading = false, error = false, url = '/' } = this.props;

        //menu de usuario
        const user_menu = (
            <Menu
                id="menu-appbar"
                anchorEl={this.state.anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={this.state.profileMenuOpen}
                onClose={(e) => this.handleProfileMenu(e,"close")}
            >
                <MenuItem component={(props) => <Link to="/cambiar-clave" {...props}/>}>Cambiar clave</MenuItem>
                <MenuItem onClick={this.logout}>Salir</MenuItem>
            </Menu>
        );

        return (
            <div className={classes.root}>
                <AppBar position="fixed" className={classes.appBar}>
                    <Toolbar>
                        <Link to="/">
                            <img src="https://dk0k1i3js6c49.cloudfront.net/landingpage/images/v4/empretienda-green-logo.png" alt="Logo panel" className={classes.logo}/>
                        </Link>
                        <div className={classes.grow} />
                        <div className={classes.sectionUser}>
                            <ToggleUI show={is_mobile}>
                                <IconButton
                                    aria-owns={this.state.mobileOpen ? 'menu-appbar' : undefined}
                                    aria-haspopup="true"
                                    onClick={(e) => this.handleProfileMenu(e,"open")}
                                    color="primary"
                                >
                                    <Icon>account_circle</Icon>
                                </IconButton>
                            </ToggleUI>
                            <ToggleUI show={!is_mobile}>
                                <Button color="inherit" classes={{root: classes.navbarButton }} onClick={(e) => this.handleProfileMenu(e,"open")}>
                                    <Icon style={{marginRight: 5}}>person</Icon> Cuenta
                                </Button>
                            </ToggleUI> 
                            { user_menu }
                        </div>
                    </Toolbar>
                </AppBar>
                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    {
                        loading ? <LinearProgress /> : (error ? <ErrorLayout url={url}/> : this.props.children)
                    }
                </main>
            </div>
        );
    }
}

const styles = theme => ({
    root: {
        display: 'flex'
    },
    badge: {
        background: '#ec268f'
    },
    logo: {
        width: 'auto',
        height: '20px',
        marginLeft: '-10px',
        margin: 'auto',
        display: 'block',
        [theme.breakpoints.up('sm')]: {
            height: '28px',
        }
    },
    drawer: {
        [theme.breakpoints.up('md')]: {
            width: '300px',
            flexShrink: 0,
        }
    },
    nested: {
        paddingLeft: 0
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing.unit * 2,
        marginLeft: 0,
        width: '100%',
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'block'
        },
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing.unit * 3,
            width: 'auto',
        }
    },
    grow: {
        flexGrow: 1
    },
    navbarButton: {
        textTransform: 'none',
        color: theme.texts.navbar_buttons
    },
    toolbar: theme.mixins.toolbar,
    toolbar_sidenav: {
        ...theme.mixins.toolbar,
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        padding: '0px 5px',
        display:'flex',
        justifyContent:'flex-end',
        alignItems: 'center'
    },
    searchIcon: {
        width: theme.spacing.unit * 9,
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    appBar: {
        backgroundColor: '#fff',
        boxShadow: '0 0.07em 0.125em 0 rgba(0,0,0,.15)',
        zIndex: theme.zIndex.drawer+2
    },
    menuButton: {
        marginRight: 20,
        color: theme.texts.menu_hambuger,
        [theme.breakpoints.up('md')]: {
            display: 'none'
        },
    },
    drawerPaper: {
        width: '300px',
        zIndex: theme.zIndex.drawer,
        border: '0px solid transparent'
    },
    content: {
        flexGrow: 1,
        boxSizing: 'border-box',
        padding: theme.spacing.unit * 3
    },
    inputRoot: {
        color: 'inherit',
        width: '100%'
    },
    inputInput: {
        paddingTop: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        paddingLeft: theme.spacing.unit * 10,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 200
        }
    },
    sectionUser: {
        display: 'flex'
    },
    list_icon: {
        marginRight: '3px',
        color: theme.texts.navbar_buttons
    },
    list_item_menu: {
        color: theme.texts.navbar_buttons,
        [theme.breakpoints.up('md')]: {
            padding: '7.5px'
        }
    },
    list_item_menu_icon: {
        color: theme.texts.navbar_buttons
    }
});

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        adminLogout: () => dispatch(adminLogout())
    }
};

export const PrivateLayout = withStyles(styles, { withTheme: true })(connect(mapStateToProps,mapDispatchToProps)(PrivateLayout_));