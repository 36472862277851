import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import App from './App';
import * as serviceWorker from './serviceWorker';

//redux
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import rootReducer from './store/reducers/rootReducer';
import { adminAuthSet, adminLogout } from './store/actions';

//configuracion default de axios
axios.defaults.timeout = 300000; //seteo el timeout de los requests

const composeEnhancers = window._REDUX_DEVTOOLS_EXTENSION_COMPOSE_ || compose;
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

//armar logica
if(localStorage.getItem('auth'))
{
    axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('auth');
    store.dispatch(adminAuthSet());
}
else
{
    store.dispatch(adminLogout());
}

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>, document.getElementById('root'));

serviceWorker.unregister();
