/*
    Author: Luciano Ghione  
    Scope: Web
    Version: 1.0.0
    SelectUI is a react function component based on Material UI which is used to handle 
    user advanced controlled selects in an easy way and with good compatibility.
*/

import React from 'react';
import PropTypes from 'prop-types';
import { 
    ListItemAvatar,
    ListItemText,
    Avatar,
    MenuItem, 
    TextField,
    withStyles 
} from '@material-ui/core';
import { Validate } from '../../other';

const SelectUIOptions = (options,native,classes,icons,value_atribute,label_atribute,image_atribute,image_prefix_url) => {

    return options.map((option) => {

        if(native)
        {
            return <option value={option[value_atribute]} key={option[value_atribute]}>{option[label_atribute]}</option>;
        }
        else
        {
            if(icons)
            {
                return (
                    <MenuItem value={option[value_atribute]} key={option[value_atribute]}>
                        <ListItemAvatar>
                            <Avatar alt={'select-avatar-'+option[label_atribute]} src={image_prefix_url+option[image_atribute]} className={classes.avatar}/>
                        </ListItemAvatar>
                        <ListItemText primary={option[label_atribute]} />
                    </MenuItem>
                );
            }
            else
            {
                return (
                    <MenuItem value={option[value_atribute]} key={option[value_atribute]}>
                        {option[label_atribute]}
                    </MenuItem>
                );
            }
        }

    });

}

const renderValue = (value,options,value_atribute,label_atribute) => {

    if(!options.length)
    {
        return '';
    }
    else
    {
        return options.filter(opt => value===opt[value_atribute])[0][label_atribute];
    }

}

const forceUpdateAndRenderWithFirstOption = (handleChangeSelect,value,name,change_param) => {
    let fake_e = {
        target: {
            value: value,
            name: name
        }
    };
    handleChangeSelect(fake_e,change_param);
}

const SelectUI_ = (props) => {

    //if the client is a mobile device, then show
    //primitive options to improve compatibility
    let native = Validate.is_mobile();

    let { 
        classes, 
        data, 
        options, 
        handleChangeSelect, 
        icons = false,
        value_atribute = 'value',
        label_atribute = 'label',
        image_atribute = 'image',
        image_prefix_url = '',
        variant = 'outlined',
        fullWidth = true
    } = props;

    let { 
        //required, 
        error, 
        id,
        name, 
        label, 
        value, 
        change_param = null,
        messages
    } = data;

    //if value null and there is options
    //force the first option of array 
    //to be choosen and update data.value 
    //with its value
    if(value===null && options.length)
    {
        forceUpdateAndRenderWithFirstOption(handleChangeSelect,options[0][value_atribute],name,change_param);
    }

    //if value null, bypass first render and prevent null warning
    value = value===null ? '' : value;

    let select_props = (icons && !native) ? {
        native: native,
        classes: { select: classes.selected },
        renderValue: (value) => renderValue(value,options,value_atribute,label_atribute)
    } : {
        native: native,
        classes: { select: classes.selected },
        renderValue: (value) => renderValue(value,options,value_atribute,label_atribute)
    };
 
    return (
        <TextField
            select
            id={id}
            fullWidth={fullWidth}
            name={name}
            error={error}
            label={label}
            value={value}
            margin="dense"
            variant={variant}
            SelectProps={select_props}
            InputLabelProps={{
                shrink: true
            }}
            FormHelperTextProps={{
                className: classes.helper_text
            }}
            onChange={(e) => handleChangeSelect(e,change_param)}
            helperText={error ? messages.error : messages.help}
        >
            { SelectUIOptions(options,native,classes,icons,value_atribute,label_atribute,image_atribute,image_prefix_url) }
        </TextField>
    );
}

const styles = theme => ({
    avatar: {
        width: "30px",
        height: "30px",
        margin: 0
    },
    helper_text:{
        marginLeft: 0
    },
    selected: {
        backgroundColor: 'rgba(0, 0, 0, 0)',
        '&:hover' : {
            backgroundColor: 'rgba(0, 0, 0, 0)'
        },
        '&:focus' : {
            backgroundColor: 'rgba(0, 0, 0, 0)'
        },
    }
});

SelectUI_.propTypes = {

    //Object used to add some styling with withStyles
    classes: PropTypes.object,

    //Data to make the select
    data: PropTypes.shape({
        validate:       PropTypes.bool.isRequired, //determina si hay que validarlo en submit
        required:       PropTypes.bool.isRequired,
        error:          PropTypes.bool.isRequired,
        label:          PropTypes.string.isRequired,
        id:             PropTypes.string.isRequired,
        name:           PropTypes.string.isRequired,
        change_param:   PropTypes.any,  //adds a second parameter to handleChangeSelect if needed
        value:          PropTypes.oneOfType([PropTypes.number,PropTypes.string]),
        messages:       PropTypes.shape({
            help:   PropTypes.string,
            error:  PropTypes.string.isRequired
        }) 
    }),

    //The array of objects to display the options
    options: PropTypes.arrayOf(PropTypes.object).isRequired,

    //Function used to update the controlled select
    //handleChangeSelect(e,change_param = null)
    handleChangeSelect: PropTypes.func.isRequired,

    //true if there are images to show on options false if not
    //Default: true
    icons: PropTypes.bool,

    //The atribute of options we want to get the value of
    //Default: options.value
    value_atribute: PropTypes.string,

    //The atribute of options we want to show on label
    //Default: options.label
    label_atribute: PropTypes.string,
    
    //The atribute of options we want to show as icon/image
    //Used only if icons is set to true
    //Default: options.image
    image_atribute: PropTypes.string,
    
    //if the image is saved on cdn or cloud
    //then set the url for it if it's necessary
    //Default: ''
    image_prefix_url: PropTypes.string,

};

export const SelectUI = withStyles(styles)(SelectUI_);