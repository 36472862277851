/* eslint-disable react/jsx-no-duplicate-props */
/*
    Author: Luciano Ghione  
    Scope: Web
    Version: 1.0.0
    InputUI is a react function component based on Material UI which is used to handle 
    user advanced controlled inputs in an easy way.
*/

import React from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid/v1';
import { TextField, InputAdornment, withStyles } from '@material-ui/core';
import Check from '@material-ui/icons/Check';
import { Validate } from '../../other';

const InputUI_ = (props) => {

    let {
        classes, 
        handleChange, 
        adornment = false,
        adornment_extra = false,
        end_adornment = false,
        multiline = false,
        step = "1",
        input 
    } = props;
    
    let {
        auth, 
        required,
        disabled, 
        error,
        type, 
        value, 
        label, 
        id, 
        name, 
        messages, 
        change_param = null,
        placeholder = null
    } = input;

    let inputProps = type==="number" ? { step : step, min: '0', className: classes.input } : {className: classes.input};

    id = id ? id : (name+'_'+uuid().split('-')[0]);

    return (
        <div className={classes.root}>
            <TextField
                id={id}
                disabled={disabled}
                classes={{root: classes.textField}}
                variant="outlined"
                type={type}
                margin="dense"
                name={name}
                error={error}
                multiline={multiline}
                label={required ? label : label+' (opcional)'}
                value={value}
                onChange={(e) => handleChange(e,false,change_param)}
                onBlur={(e) => handleChange(e,true,change_param)}
                placeholder={placeholder}
                inputProps={inputProps}
                helperText={
                    error ? (
                        messages.error+' '+messages.error_extra
                    ) : (
                        messages.help ? messages.help : ''
                    )
                }
                InputLabelProps={{
                    shrink: true
                }}
                FormHelperTextProps={{
                    className: classes.helper_text
                }}
                InputProps={{
                    endAdornment: (
                        (!error && auth && !Validate.is_falsy(value)) ? (
                            <InputAdornment position="end">
                                {end_adornment ? end_adornment : null}
                                <Check classes={{root: classes.tick}}/>
                            </InputAdornment>
                        ) : (end_adornment ? end_adornment : null)
                    ),
                    startAdornment: (
                        adornment ? (<InputAdornment position="start">
                                {adornment}
                                {adornment_extra ? adornment_extra : null}
                            </InputAdornment>) : null
                    )
                }}
            />
        </div>
    );


}

const styles = theme => ({
    tick: {
        color: '#9e9e9e',
        fontSize: '1.2rem'
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    helper_text:{
        marginLeft: 0
    },
    textField: {
        flex: 1,
        backgroundColor: 'rgba(0, 0, 0, 0)',
    },
    input: {
        backgroundColor: 'rgba(0, 0, 0, 0)',
        '&:hover' : {
            backgroundColor: 'rgba(0, 0, 0, 0)'
        },
        '&:focus' : {
            backgroundColor: 'rgba(0, 0, 0, 0)'
        },
        "&:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 1000px white inset"
        }
    },
});

InputUI_.propTypes = {

    //Object used to add some styling with withStyles
    classes: PropTypes.object,

    //Function used to update controlled input
    //handleChange(e,blur,change_param = null)
    handleChange: PropTypes.func.isRequired,

    //Adds an icon or text as input prefix
    adornment: PropTypes.oneOfType([ 
        PropTypes.bool,     //if false it has no adornment
        PropTypes.string,   //example: '$'
        PropTypes.element   //example: <Icon/>
    ]),

    //Adds a second icon or text as input prefix (adornment has to exist)
    adornment_extra: PropTypes.oneOfType([ 
        PropTypes.bool,     //if false it has no adornment
        PropTypes.string,   //example: '$'
        PropTypes.element   //example: <Icon/>
    ]),

    //Adds an icon or text as input suffix
    end_adornment: PropTypes.oneOfType([ 
        PropTypes.bool,     //if false it has no adornment
        PropTypes.string,   //example: 'kg'
        PropTypes.element   //example: <Icon/>
    ]),

    //false for input and true for textarea
    //Default: false
    multiline: PropTypes.bool,

    //defines de step of a input of type number
    step: PropTypes.string,

    //The object initialized in state
    input: PropTypes.shape({
        auth:           PropTypes.bool.isRequired,
        validate:       PropTypes.bool.isRequired, //determina si hay que validarlo en submit
        required:       PropTypes.bool.isRequired, 
        disabled:       PropTypes.bool.isRequired, 
        error:          PropTypes.bool.isRequired,
        type:           PropTypes.oneOf(['text','number','password','email','tel','date','datetime-local','color']).isRequired,
        value:          PropTypes.oneOfType([PropTypes.number,PropTypes.string]).isRequired,
        default_value:  PropTypes.oneOfType([PropTypes.number,PropTypes.string]).isRequired,
        label:          PropTypes.string.isRequired,
        id:             PropTypes.string.isRequired,
        name:           PropTypes.string.isRequired, 
        regex:          PropTypes.instanceOf(RegExp).isRequired,
        change_param:   PropTypes.any, //adds a third parameter to handleChange if needed
        placeholder:    PropTypes.any,  
        messages:       PropTypes.shape({
            help:   PropTypes.string,
            error:  PropTypes.string.isRequired,
            error_extra: PropTypes.string.isRequired
        }) 
    })

};

export const InputUI = withStyles(styles)(InputUI_);
