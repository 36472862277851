import { combineReducers } from 'redux';
import { 
    authReducer,
    alianzaReducer,
    cuponAlianzaReducer,
    cuponLeadsReducer
} from './';

const rootReducer = combineReducers({
    auth: authReducer,
    alianza: alianzaReducer,
    cupones: cuponAlianzaReducer,
    leads: cuponLeadsReducer
});

export default rootReducer;

