import axios from 'axios';
import Reducers from '../reducers';
import { Config, Validate } from '../../other';

const adminAuthSet = () => {
    return (dispatch, getState) => {
        //guardo en redux
        dispatch({type: Reducers.AUTH_SET, data: localStorage.getItem('auth')});
    }
}

const adminLogin = (usuario,callback) => {
    return (dispatch, getState) => {
        axios.post(Config.BACKEND_ENDPOINT+'/alianza/login', usuario).then(resp => {
            
            //guardo en redux
            const token = resp.data.data.token;
            // console.log(token)
            axios.defaults.headers.common['Authorization'] = 'Bearer '+token;
            localStorage.setItem('auth', token);
            dispatch({ type: Reducers.AUTH_SET, data: token });

            return callback(false, null);
            
        }).catch(err => {
            return callback(true, Validate.procesar_error_axios(err.response));
        });
    }
}

const adminLogout = () => {
    return (dispatch, getState) => {
        if(localStorage.getItem('auth'))
        {
            localStorage.removeItem('auth');
        }
        dispatch({type: Reducers.AUTH_UNSET});   
    }
}

export { 
    adminAuthSet, 
    adminLogin, 
    adminLogout
};