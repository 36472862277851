import React, { Component } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { PublicRoute, PrivateRoute, ThemeUI } from './components/common';
import { 
    Login,
    CambiarClave,
    Leads,
    CuponAlianza
} from './components';

//styles
import './libs/css/style.css';

class App extends Component 
{
    render() 
    {
        return (
            <ThemeUI>
                <BrowserRouter>
                    <Switch>
                        <PrivateRoute exact path="/" component={CuponAlianza} />
                        <PrivateRoute exact path="/cambiar-clave" component={CambiarClave} />
                        <PublicRoute exact path="/login" component={Login} />
                        <PrivateRoute exact path="/panel-alianzas/cupon-alianza/:idCuponAlianza" component={Leads} />
                    </Switch>
                </BrowserRouter>
            </ThemeUI>
        );
    }
}

export default App;
