import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    List,
    ListItem,
    ListItemText,
    withStyles
} from '@material-ui/core';
import { Config, Validate } from '../../../other';

const LeadsList_ = (props) => {
    let {cupon, classes, filtro} = props;
    let leadArray = cupon.leads;
    const renderizarLeads = (cupon,classes) => {
        if(leadArray.length>0)
        {
            return leadArray.filter( lead => {
                let {t_nombre, d_nombre, t_email} = lead;
                let busqueda = filtro.search.value.toLowerCase();
                let valor_select = filtro.filtro_abonados.value;
                if(
                    (
                        (t_nombre !== null && t_nombre.toLowerCase().includes(busqueda))
                        || (d_nombre !== null && d_nombre.toLowerCase().includes(busqueda))
                        || (t_email !== null && t_email.toLowerCase().includes(busqueda))
                    )
                    &&
                    (
                        (lead.f_paga===1 && lead.cat_pago===1 && valor_select===0)
                        || (lead.f_paga===1 && lead.cat_pago===0 && valor_select===1)
                        || (lead.f_paga===0 && valor_select===2)
                        || (valor_select===-1)
                    )
                ) 
                {
                    return true;
                }
                else
                {
                    return false;
                }
            }).map((lead,index) => {
        
                return (
                    <ListItem divider={index<(leadArray.length-1)} key={index} className={classes.list_item} >
                        <ListItemText
                            primary={lead.t_nombre}
                            classes={{root: classes.list_text}}
                            secondary={
                                <span className={classes.span}>
                                    <span className={classes.span}>Email: {lead.t_email}</span>
                                    <span className={classes.span}>Dominio: {lead.d_nombre}</span>
                                    <span className={classes.span}>Fecha de uso: {Validate.utc2local_format(lead.cat_fecha_uso,Config.DATE_FORMAT)}</span>
                                    <span className={classes.span}>Pagó: {lead.f_paga ? 'Sí' : 'No'}</span>
                                    <span className={classes.span}>En funcionamiento: {lead.funcionando ? 'Sí' : 'No'}</span>
                                </span>
                            }
                        />
                    </ListItem>
                );
            });
        }
        else
        {
            return (
                <ListItem>
                    <ListItemText 
                        primary="No se han encontrado leads"
                    />
                </ListItem>
            );
        }
    }
    return (
        <List classes={{root: classes.list}}>
            {renderizarLeads(cupon,classes)}
        </List>
    );
}

const styles = theme => ({
    span: {
        display: 'block',
        width: '90%',
        [theme.breakpoints.up('md')]: {
            width: 'auto'
        }
    },
    list: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        padding: '0',
        '&:last-child': {
            borderBottom: '0px solid transparent'
        }
    },
    list_text: {
        paddingRight: '100px'
    }
});

const mapStateToProps = (state) => {
    return {
        cupon: state.leads.length ? state.leads[0] : {leads: []},
        auth: state.auth,
    }
}

const mapDispatchToProps = null;

LeadsList_.propTypes = {

    filtro: PropTypes.object.isRequired

};

export const LeadsList = connect(mapStateToProps,mapDispatchToProps)(withStyles(styles,{withTheme: true})(LeadsList_));
