export default class Reducers
{
    /********/
    /* Auth */
    /********/
    static AUTH_SET = 'AUTH_SET'; 
    static AUTH_UNSET = 'AUTH_UNSET';

    /***********/
    /* Alianza  */
    /***********/
    static ALIANZA_GUARDAR = 'ALIANZA_GUARDAR';

    /***********/
    /* Cupon alianza  */
    /***********/
    static CUPON_ALIANZA_GUARDAR = 'CUPON_ALIANZA_GUARDAR';

    /********/
    /* Leads */
    /********/
    static CUPON_LEADS_GUARDAR = 'CUPON_LEADS_GUARDAR';
}