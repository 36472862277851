import React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    texts: {
        module_title: '#333',
        module_subtitle: '#666',
        menu_hambuger: '#333',
        navbar_buttons: '#666',
        card_title: '#333',
        card_subtitle: '#666',
        primary: '#333',
        secondary: '#666',
        light: '#999'
    },
    palette: {
        primary: { 
            main: '#00b65f',
            contrastText: '#fff',
        }
    },
    //styles for module cards
    card: {
        card: {
            borderRadius: '4px',
            boxShadow: '0 0.07em 0.125em 0 rgba(0,0,0,.15)',
            overflow: 'visible'
        },
        card_below: {
            borderRadius: '4px',
            boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.08)',
            overflow: 'visible',
            marginTop: '20px'
        },
        card_content: {
            padding: '30px 20px'
        },
        card_header: {
            paddingTop: 25,
            paddingBottom: 25
        },
        card_header_title: {
            fontWeight: 300
        },
        card_actions: {
            paddingBottom: 20,
            paddingLeft: 10
        },
        card_action_button: {
            margin: 0,
            alignSelf: 'center'
        },
        card_actions_public: {
            padding: '10px 5px',
            boxSizing: 'border-box'
        },
        card_subheader: {
            padding: 0,
            margin: 0,
            color: '#9e9e9e'
        },
        card_subheader_icon: {
            fontSize: 'inherit',
            lineHeight: 'inherit'
        }
    },
    chip: {
        backgroundColor: '#e4e4e4',
        color: 'rgba(0,0,0,0.6)',
        fontSize: '11px',
        margin: '10px 5px 5px 0px'
    }
});

const ThemeUI = (props) => {
    return (
        <MuiThemeProvider theme={theme}>
            {props.children}
        </MuiThemeProvider>
    );
}

export {ThemeUI};
