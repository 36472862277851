import jwt_decode from 'jwt-decode';
import Reducers from '../reducers';

const initState = {
    autenticado: false,
    rol: null,
    idAgenteSoporte: null,
    as_nombre_completo: '',
    as_email: ''
};

const authReducer = (state = initState,action) => {

    switch(action.type)
    {
        case Reducers.AUTH_SET:
            
            let { rol, sub, nombre_completo, email } = jwt_decode(action.data);
            
            return {
                ...state,
                autenticado: true,
                rol: rol,
                idAgenteSoporte: sub,
                as_nombre_completo: nombre_completo,
                as_email: email
            };

        case Reducers.AUTH_UNSET:  
            return initState;

        default:
            return state;
    }
}

export {authReducer};